import React from 'react';
import styles from './MagazinesPrintsContent.module.scss';
import classNames from 'classnames/bind';
import { graphql, useStaticQuery } from 'gatsby';
import DESCRIPTIONS from '../../../constants/magazinesPrints';
import Image from '../../Image/Image';

const cx = classNames.bind(styles);

const MagazinesPrintsContent = () => {
  const data = useStaticQuery(graphql`query MagazinesPrintsContent {
            allCloudinaryMedia(sort: {fields: public_id}, filter: {public_id: {regex: "/gallery/magazines-prints/"}}) {
              edges {
                node {
                  public_id
                  secure_url
                }
              }
            }
        }`);

  const urls = data.allCloudinaryMedia.edges.map((image) => image.node.secure_url);

  function renderElement(url, element) {
    return (
      <div className={cx('magazinesPrintContent__element')}>
        <Image url={url}/>
        {element}
      </div>
    );
  }

  function renderFirstColumn(urls) {
    return (
      <div>
        {renderElement(urls[0], DESCRIPTIONS.img_0)}
        {renderElement(urls[3], DESCRIPTIONS.img_3)}
        {renderElement(urls[6], DESCRIPTIONS.img_6)}
      </div>
    );
  }

  function renderSecondColumn(urls) {
    return (
      <div>
        {renderElement(urls[1], DESCRIPTIONS.img_1)}
        {renderElement(urls[4], DESCRIPTIONS.img_4)}
        {renderElement(urls[7], DESCRIPTIONS.img_7)}
      </div>
    );
  }

  function renderThirdColumn(urls) {
    return (
      <div>
        {renderElement(urls[2], DESCRIPTIONS.img_2)}
        {renderElement(urls[5], DESCRIPTIONS.img_5)}
        {renderElement(urls[8], DESCRIPTIONS.img_8)}
      </div>
    );
  }

  return (
    <div className={styles.magazinesPrintContent}>
      {renderFirstColumn(urls)}
      {renderSecondColumn(urls)}
      {renderThirdColumn(urls)}
    </div>
  );
};

export default MagazinesPrintsContent;