// @flow

import React from 'react';
import Layout from '../components/Layout/Layout';
import ProjectLayout from '../components/ProjectLayout/ProjectLayout';
import MagazinesPrintsContent from '../components/ProjectsContent/MagazinesPrintsContent/MagazinesPrintsContent';

const MagazinesPrintsPage = ({location: {pathname}}) => {
  const description = () => (
    <div style={{maxWidth: "500px"}}>
      A collection of magazine spreads, covers,
      <br/>
      invitations, posters and booklets.
    </div>
  );

  return (
    <ProjectLayout pathname={pathname} description={description()}>
      <MagazinesPrintsContent />
    </ProjectLayout>
  );
};

export default MagazinesPrintsPage;
