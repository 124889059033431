// @flow strict
import React from 'react';

const DESCRIPTIONS = {
  img_0: (
    <p>
      Fashion and lifestyle magazine <i>AŠ IKONA</i> spread.<br/>
      Made in <i>Virtuvės mitų griovėjai</i>
    </p>
  ),
  img_1: (
    <p>
      Mother’s day <i>MAXIMA</i> cover publication.<br/>
      Made in ALL CAPS agency
    </p>
  ),
  img_2: (
    <p>
      <i>AŠ IKONA</i> calendar & workbook design.<br/>
      Made in <i>Virtuvės mitų griovėjai</i>
    </p>
  ),
  img_3: (
    <p>
      Culinary magazine <i>VERDU IR KEPU</i> spread.<br/>
      Made in <i>Virtuvės mitų griovėjai</i>
    </p>
  ),
  img_4: (
    <p>
      Invitation for <i>AŠ IKONA</i> 13th magazine<br/>
      publication.<br/>
      Made in <i>Virtuvės mitų griovėjai</i>
    </p>
  ),
  img_5: (
    <p>
      <i>Camelia</i> publication spread.<br/>
      Made in <i>ALL CAPS</i> agency
    </p>
  ),
  img_6: (
    <p>
      Recipes booklet in colaboration with <i>HELLMANN’S</i><br/>
      Made in <i>Virtuvės mitų griovėjai</i>
    </p>
  ),
  img_7: (
    <p>
      Poster for <i>GUACAMOLE</i> restaurant.<br/>
      Made in <i>Virtuvės mitų griovėjai</i>
    </p>
  ),
  img_8: (
    <p>
      Poster for <i>MANAMI</i> restaurant.<br/>
      Made in <i>Virtuvės mitų griovėjai</i>
    </p>
  ),
};

export default DESCRIPTIONS;